import { Service } from './service'

const PATH = 'stats/'
const SHARE_PATH = 'share/stats/'

class StatsService extends Service {
  //  SMS
  getSmsChannelDatatable (params = {}) {
    return this.get(PATH + 'sms/channel/datatable', params)
  }

  getSmsCampaignData (campaignId) {
    return this.get(PATH + 'sms/campaign/' + campaignId)
  }

  getSmsCampaignDatatable (params = {}) {
    return this.get(PATH + 'sms/campaign-datatable', params)
  }

  // MAIL

  getMailingChannelDatatable (params = {}) {
    return this.get(PATH + 'mailing/channel/datatable', params)
  }

  getMailingCampaignData (campaignId) {
    return this.get(PATH + 'mailing/campaign/' + campaignId)
  }

  getMailingCampaignDatatable (params = {}) {
    return this.get(PATH + 'mailing/campaign-datatable', params)
  }

  // WHATSAPP

  getWhatsappChannelDatatable (params = {}) {
    return this.get(PATH + 'whatsapp/channel/datatable', params)
  }

  getWhatsappCampaignData (campaignId) {
    return this.get(PATH + 'whatsapp/campaign/' + campaignId)
  }

  getWhatsappCampaignSessionsDatatable (params = {}) {
    return this.get(PATH + 'whatsapp/campaign/sessions/datatable', params)
  }

  getWhatsappCampaignDatatable (params = {}) {
    return this.get(PATH + 'whatsapp/campaign-datatable', params)
  }

  getContactPhonesByPressedAction (sendingId, action) {
    return this.get(PATH + 'whatsapp/contact-phones-by-pressed-action/' + sendingId + '/' + action)
  }

  enableShareUrl (campaignId) {
    return this.get(PATH + 'share/campaign/enable-share-url/' + campaignId)
  }

  disableShareUrl (campaignId) {
    return this.get(PATH + 'share/campaign/disable-share-url/' + campaignId)
  }

  getShareUrl (campaignId) {
    return this.get(PATH + 'share/campaign/get-share-url/' + campaignId)
  }

  checkShareUrl (campaignId) {
    return this.get(PATH + 'share/campaign/check-share-url/' + campaignId)
  }

  getDeviceChart (params = {}) {
    return this.get(PATH + 'mailing/device-charts', params)
  }

  getDeviceChartByCampaignIds (params = {}) {
    return this.get(PATH + 'mailing/device-charts-campaigns', params)
  }

  // share routes

  getShareSmsCampaignData (campaignId, shareHash) {
    return this.get(SHARE_PATH + 'sms/campaign/' + campaignId + '?shareHash=' + shareHash)
  }

  getShareSmsCampaignDatatable (params = {}, shareHash) {
    return this.get(SHARE_PATH + 'sms/campaign-datatable' + '?shareHash=' + shareHash, params)
  }

  getShareMailingCampaignData (campaignId, shareHash) {
    return this.get(SHARE_PATH + 'mailing/campaign/' + campaignId + '?shareHash=' + shareHash)
  }

  getShareMailingCampaignDatatable (params = {}, shareHash) {
    return this.get(SHARE_PATH + 'mailing/campaign-datatable' + '?shareHash=' + shareHash, params)
  }

  getContactsFiltered (params = {}, channel) {
    return this.get(PATH + channel + '/contact-phones', params)
  }

  getContactsPhonesClickedUrl (params = {}) {
    return this.post(PATH + 'sms/contact-phones-clicked-url', params)
  }

  getContactsEmailsClickedUrl (params = {}) {
    return this.post(PATH + 'mailing/contact-emails-clicked-url', params)
  }

  getCustomFieldsWithSendingId (params = {}) {
    return this.post(PATH + 'custom-fields-by-sending', params)
  }

  getCustomFieldsFromCampaignIds (params = {}) {
    return this.post(PATH + 'custom-fields-by-campaigns', params)
  }
}

export default new StatsService()
